




import { Component, Vue } from 'vue-property-decorator'
import ImageVertical from '@/components/modules/drills/molecules/ImageVertical.vue'

@Component({
  components: {
    ImageVertical,
  },
})
export default class QuestionImageVertical extends Vue {
  private mounted() {
    Vue.prototype.$logger.info('-- QuestionImageVertical mounted')
  }
}
