





import { Component, Vue } from 'vue-property-decorator'
import DrillTitle from '@/components/modules/drills/atoms/Title.vue'
import DrillPen from '@/components/organisms/study/DrillPen.vue'

@Component({ components: { DrillTitle, DrillPen } })
export default class TitleWithPenSwitcher extends Vue {
  private title = ''
}
