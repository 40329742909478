





import { Component, Mixins, Vue } from 'vue-property-decorator'

// TODO 必要なコンポーネントのみ読み込む
import QuestionImageHorizontal from '@/components/modules/drills/organisms/QuestionImageHorizontal.vue'
import QuestionImageVertical from '@/components/modules/drills/organisms/QuestionImageVertical.vue'
import Transition from '@/components/modules/drills/organisms/Transition.vue'
import TitleWithPenSwitcher from '@/components/modules/drills/molecules/TitleWithPenSwitcher.vue'
import Title from '@/components/modules/drills/atoms/Title.vue'

import DrillsConfig from '@/mixins/drills/Config' // TODO GDLS-600 mixinsではないがここにひとまず入れておく
import DrillWebApi from '@/mixins/drills/WebApi'

@Component({
  components: {
    QuestionImageHorizontal,
    QuestionImageVertical,
    Transition,
    Title,
    TitleWithPenSwitcher,
  },
})
export default class Drill extends Mixins(DrillWebApi) {
  private get isReport() {
    return this.$route.path.includes('report')
  }

  private drillModules = DrillsConfig.drillModules

  private subjectCode = this.$route.query?.subjectCode || Vue.prototype.$cookies.get('dataGdls').subjectCode
  private classMode = this.$route.query?.classMode || Vue.prototype.$cookies.get('dataGdls').classMode

  protected get lessonId() {
    return this.$store.getters['lessonActionCable/lessonId']
  }

  // 教科によって縦画面か横画面か読み込むモジュールを変更する（恐らく定期テストや一問一答が入ってくると名称を変更する）
  private get drillModulesByCode() {
    Vue.prototype.$logger.info('-- Drill drillModulesBySubject')
    let drillMoules = []
    if (this.curriculumModeCode === 'OO') {
      drillMoules = this.subjectCode === 'ko' ? this.drillModules.AUTO_ONE_TEXT_VR : this.drillModules.AUTO_ONE_TEXT_HR
    } else if (this.classMode === 'NY') {
      // 入試対策は国語で読解文がある場合のみ縦画面
      drillMoules = this.subjectCode === 'ko' && this.hasDokkai ? this.drillModules.SELF_VR : this.drillModules.SELF_HR
    } else {
      drillMoules = this.subjectCode === 'ko' ? this.drillModules.SELF_VR : this.drillModules.SELF_HR
    }
    //remove component Transition
    const resultDrill = [...drillMoules]
    if (this.isReport) {
      resultDrill.includes('Transition') && resultDrill.pop()
    }
    return resultDrill
  }

  private get hasDokkai() {
    const imageUrls = this.$store.getters['drills/imageUrls']
    if (!imageUrls) return false
    if (!imageUrls[0]) return false
    if (!imageUrls[0].d) return false
    return imageUrls[0].d.length > 0
  }

  /**
   * 授業種別コード
   *
   * @return 授業種別コード
   */
  private get classCategoryCode(): string {
    return Array.isArray(this.$route.query.classCategoryCode)
      ? this.$route.query.classCategoryCode[0] || ''
      : this.$route.query.classCategoryCode || ''
  }

  /**
   * カリキュラムモードコード
   *
   * @return カリキュラムモードコード
   */
  private get curriculumModeCode(): string {
    return Array.isArray(this.$route.query.curriculumModeCode)
      ? this.$route.query.curriculumModeCode[0] || ''
      : this.$route.query.curriculumModeCode || ''
  }

  private setNoticeCurrentPageText() {
    let result = ''

    if (this.classCategoryCode === 'EN') {
      result = `演習実施中 ${this.$route.query.page}問目 表示`
    } else if (this.classCategoryCode === 'RK') {
      result = `理解度テスト実施中 ${this.$route.query.page}問目 表示`
    } else if (this.classCategoryCode === 'KK') {
      result = `確認テスト実施中 ${this.$route.query.page}問目 表示`
    } else if (this.classCategoryCode === 'SY') {
      result = `宿題実施中 ${this.$route.query.page}問目 表示`
    }

    // 補助教材選択中
    if (this.curriculumModeCode === 'OO') {
      result = `補助教材テスト実施中 ${this.$route.query.page}問目 表示`
    }

    this.$store.commit('drills/setNoticeCurrentPageText', result)
  }

  protected created() {
    this.setNoticeCurrentPageText()
  }

  protected async mounted(): Promise<any> {
    Vue.prototype.$logger.info('-- Drill mounted')

    // 問題開始APIを呼ぶ
    await this.startProblemApi({
      lessonId: this.lessonId,
      resultDrillId: Number(this.$route.query.resultDrillId),
      page: Number(this.$route.query.page),
    })
  }
}
